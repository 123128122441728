.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  max-width: 1200px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin: 80px 16px 32px 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
  color: #39ff14;
}

